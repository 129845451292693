import { Payments } from '@sanardigital/cross-payments-sdk';
import { ParsedUrlQuery } from 'querystring';
import { PaymentInfo } from 'utils/types/paymentInfo';
import { createPaymentSubscription } from 'services/lambda/payment-subscription';
import { findOrCreateUser } from 'services/lambda/user';
import { encrypt } from 'services/lambda/encrypt';
import { Field } from 'utils/types/field';
import { Infos } from 'utils/types/infos';

import { Order } from 'utils/types/order';
import { User } from 'utils/types/user';

import env from '@beam-australia/react-env';

const PaymentEnvironments: any = env('PAYMENT_ENVIRONMENTS') || 'dev';

const payments = new Payments(PaymentEnvironments);

export const sendNewPayment = async (
    paymentInfo: PaymentInfo,
    infos: Infos,
    fields: Field,
    user: User,
    order: Order,
    query?: ParsedUrlQuery
) => {
    const { client, userAgent } = infos;

    const token = !!paymentInfo.creditCardNumber
        ? await createCardTokenNewPayments(paymentInfo)
        : undefined;

    const metadata =
        order.planType === 'mensal'
            ? {
                  experimentName: query?.experimentName,
                  experimentVariant: query?.experimentVariant,
              }
            : undefined;

    const payload = createPayloadNewPayments(
        user,
        fields,
        token,
        order,
        query?.imb_id,
        metadata
    );

    const encrypted = encrypt(payload, client?.pubKey);

    const data = await createPaymentSubscription(
        encrypted,
        paymentInfo.captcha,
        client?.token,
        userAgent
    );

    return data;
};

export const verifyEmailNewPayment = async (values, infos: Infos) => {
    const { client, userAgent } = infos;

    const payload = JSON.stringify({
        email: values?.email,
        name: values?.name,
        cpf: values?.cpf,
        ingressYear: values?.year,
        ingressSemester: values?.semester,
        phone: {
            countryCode: values?.ddi,
            number: values?.telephone,
        },
    });

    const encrypted = encrypt(payload, client?.pubKey);
    const data = await findOrCreateUser(encrypted, client?.token, userAgent);

    return data;
};

const createCardTokenNewPayments = async (paymentInfo: PaymentInfo) => {
    const token = await payments.getCardPaymentToken({
        cardExpiration: paymentInfo.creditCardExpiration,
        cardNumber: paymentInfo.creditCardNumber,
        cardSecurityCode: paymentInfo.cvv,
        holderName: paymentInfo.name,
    });
    return token;
};

const createPayloadNewPayments = (
    user: User,
    fields: Field,
    token: string,
    order: Order,
    embId?: string | string[],
    metadata?: Record<string, string | string[] | number>
) => {
    return JSON.stringify({
        customer: {
            userId: user.id,
            name: user.name,
            email: user.email,
            document: fields.cpf,
            phone: {
                number: user.phone.number,
                countryCode: user.phone.countryCode,
            },
        },
        paymentToken: token,
        paymentMethod: fields.paymentMethod,
        tastingPeriod: getTastingPeriod(order),
        coupon: order.couponCode,
        planSlug: order.plan.slug,
        installments: fields.installments,
        isWinback: order.isWinback,
        embId,
        metadata: buildMetadata(order, metadata),
    });
};

export const getTastingPeriod = (order: Order) => {
    if (
        !!order.payment.tastingPeriod &&
        !!order.payment.tastingPeriod.price &&
        order.payment.tastingPeriod.price > 0
    ) {
        return order.payment.tastingPeriod;
    }

    return order.payment.tastingPeriodDays;
};

const buildMetadata = (order: Order, custom?: Record<string, unknown>) => {
    return {
        ...custom,
        ...(order.metadata || {}),
        ...(order.payment?.metadata || {}),
        ...(order.payment?.tastingPeriod?.metadata || {}),
        ...(order.payment?.installmentOptions?.find(
            (i) => i.installment === order?.payment?.installment
        )?.metadata || {}),
    };
};
